import axios from "axios";
import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { img_300, noPicture } from "../../config/config";

import "./carousel.css";

const Carousel = ({ id, media_type }) => {
  const [credits, setCredits] = useState([]);
  const fetchCredits = async () => {
    const { data } = await axios.get(
      `https://api.themoviedb.org/3/${media_type}/${id}/credits?api_key=${process.env.REACT_APP_API_KEY}&language=en-US`
    );
    setCredits(data.cast);
  };

  useEffect(() => {
    fetchCredits();
    // eslint-disable-next-line
  }, []);

  let slicedActors = credits.length < 10 ? credits : credits.slice(0,10)

  var settings = {
    dots: true,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    arrows: false,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 710,
        settings: {
            dots: false,
            infinite: true,
            slidesToShow: 3,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 3000,
            pauseOnHover: true,
            arrows: false,
        },
      },
      {
        breakpoint: 520,
        settings: {
            dots: false,
            infinite: true,
            slidesToShow: 2,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 3000,
            pauseOnHover: true,
            arrows: false,
        },
      },
    ],
  };

  return (
    <div>
      <Slider {...settings}>
        {slicedActors.map((item, index) => (
          <div className="carouselItem" key={index}>
            <img
              src={
                item.profile_path
                  ? `${img_300}/${item.profile_path}`
                  : noPicture
              }
              alt={item?.name}
              className="carouselItem__img"
            />
            <b className="carouselItem__txt">{item?.name}</b>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Carousel;

import React from "react";
import { Link } from "react-router-dom";
import { Col } from "reactstrap";
import {
  img_300,
  img_500,
  unavaliable,
  unavailableLandscape,
} from "../../config/config";

import "./singleContent.css";
import ContentModal from "../ContentModal/ContentModal";
import Helmet from "../Helmet/Helmet";

const SingleContent = ({
  id,
  poster,
  title,
  date,
  media_type,
  vote_average,
}) => {
  const imdb = vote_average.toFixed(1);


  return (
    <ContentModal key={id} media_type={media_type} id={id} >
     
      <div className="movie__item">
        <div className="movie__img ">
          <img src={poster ? `${img_300}/${poster}` : unavaliable} />
        </div>
        <div className="info">
          <div className="movie__info">
            <h3 className="movie__name">
              <h5>{title}</h5>
            </h3>
            <p>{media_type === "movie" ? "Movie" : "Series"}</p>
            <p className={imdb >= 7.5 ? "green" : "red"}>{imdb}</p>
            <span>{date.substring(0, 4)}</span>
          </div>
        </div>
      </div>
    </ContentModal>
  );
};

export default SingleContent;

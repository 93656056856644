import {
  Button,
  Tab,
  Tabs,
  TextField,
  ThemeProvider,
  createTheme,
} from "@mui/material";

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import SearchIcon from "@mui/icons-material/Search";
import { Col, Container, Row } from "reactstrap";
import axios from "axios";
import SingleContent from "../components/UI/SingleContent";
import CustomPagination from "../components/pagination/CustomPagination";

import "../styles/search.css";
// import { useLocation } from "react-router-dom";

const Search = () => {
  const isLight = useSelector((state) => state.light.isLight);

  const [type, setType] = useState(0);
  const [page, setPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [content, setContent] = useState();
  const [numOfPages, setNumOfPages] = useState();

  // let searchRef = useRef(null);
  // const location = useLocation();

  const darkTheme = createTheme({
    palette: {
      type: "dark",
      primary: {
        main: "#fff",
      },
    },
  });

  const fetchSearch = async () => {
    const { data } = await axios.get(`
    https://api.themoviedb.org/3/search/${type ? "tv" : "movie"}?api_key=${
      process.env.REACT_APP_API_KEY
    }&language=en-US&query=${searchText}&page=${page}&include_adult=false`);

    setContent(data.results);
    setNumOfPages(data.total_pages);
  };

  useEffect(() => {
    window.scrollTo({
      top: 100,
      left: 100,
      behavior: "smooth",
    });
    fetchSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, page, searchText]);

  return (
    <section className={`section ${isLight ? "light-toggle" : "dark-toggle"}`}>
      <Container>
        <Row>
          <Col lg="12">
            <ThemeProvider theme={darkTheme}>
              <div
                style={{ display: "flex", margin: "15px 0" }}
                // ref={searchRef}
              >
                <TextField
                  style={{ flex: 1 }}
                  label="search"
                  variant="filled"
                  onChange={(e) => setSearchText(e.target.value)}
                />
                <Button variant="conained" style={{ marginLeft: 10 }}>
                  <SearchIcon color="primary" sx={{ fontSize: 30 }} />
                </Button>
              </div>

              <Tabs
                value={type}
                indicatorColor="primary"
                textColor="primary"
                onChange={(event, newValue) => {
                  setType(newValue);
                  setPage(1);
                }}
                style={{ paddingBottom: 5 }}
              >
                <Tab style={{ width: "50%" }} label="Search Movies" />
                <Tab style={{ width: "50%" }} label="Search Series" />
              </Tabs>
            </ThemeProvider>
          </Col>
        </Row>
        <Row>
          {content &&
            content.map((item) => (
              <Col lg="3" md="4" sm="6" xl="4" className="item__position">
                <SingleContent
                  key={item.id}
                  id={item.id}
                  poster={item.poster_path}
                  title={item.title || item.name}
                  date={item.first_air_date || item.release_date}
                  media_type={type ? "tv" : "movie"}
                  vote_average={item.vote_average}
                />
              </Col>
            ))}
          {/* {!content === 0 && <h3>No movies found</h3>} */}
        </Row>

        <Row className="search-page">
          {numOfPages > 1 && (
            <CustomPagination setPage={setPage} numOfPages={numOfPages} />
          )}
        </Row>
      </Container>
    </section>
  );
};

export default Search;

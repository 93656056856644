import React from "react";
import { useSelector } from "react-redux";

import { Container,Row,Col } from "reactstrap";

const Footer = () => {
  const isLight = useSelector((state) => state.light.isLight);

  return (
    <footer
      className={`footer ${isLight ? "light-toggle" : "dark-toggle"}`}
    >
      <Container>
        <Row>
          <Col lg='6'>
          
          </Col>
          <Col lg='6'>
          
          </Col>
        </Row>
      </Container>

    </footer>
  );
};

export default Footer;

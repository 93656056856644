import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import axios from "axios";
import { Container, Row, Col } from "reactstrap";
import {
  img_300,
  img_500,
  unavailableLandscape,
  unavaliable,
} from "../../config/config";
import "./content-modal.css";
import Carousel from "../slider/Carousel";
import Helmet from "../Helmet/Helmet";
// import Youtube from "@material-ui/icons/YouTube";
// import Carousel from "../Carousel/Carousel";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    width: "90%",
    height: "80%",
    backgroundColor: "#091f36",
    border: "1px solid #282c34",
    borderRadius: 10,
    color: "white",
    boxShadow: theme.shadows[19],
    padding: theme.spacing(1, 1, 3),
  },
}));

export default function TransitionsModal({ children, media_type, id }) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [content, setContent] = useState();
  const [video, setVideo] = useState();
  const [helmet, setHelmet] = useState(false);

  const handleOpen = () => {
    setOpen(true);
    setHelmet(true);
  };

  const handleClose = () => {
    setOpen(false);
    setHelmet(false);
  };

  const fetchData = async () => {
    const { data } = await axios.get(
      `https://api.themoviedb.org/3/${media_type}/${id}?api_key=${process.env.REACT_APP_API_KEY}&language=en-US`
    );

    setContent(data);
  };

  const fetchVideo = async () => {
    const { data } = await axios.get(
      `https://api.themoviedb.org/3/${media_type}/${id}/videos?api_key=${process.env.REACT_APP_API_KEY}&language=en-US`
    );

    setVideo(data.results[0]?.key);
  };

  useEffect(() => {
    fetchData();
    fetchVideo();
    // eslint-disable-next-line
  }, [open]);

  return (
    <>
      <div
        className="media movie"
        style={{ cursor: "pointer" }}
        color="inherit"
        onClick={handleOpen}
      >
        {open ? (
          <Helmet title={content.name || content.title} />
        ) : (
          <Helmet title="Movie App" />
        )}

        {children}
      </div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          {content && (
            <div className={classes.paper}>
              <div className="ContentModal">
                <div className="ContentModal__normal">
                  <img
                    src={
                      content.backdrop_path
                        ? `${img_500}/${content.poster_path}`
                        : unavailableLandscape
                    }
                    alt={content.name || content.title}
                    className="ContentModal__portrait"
                  />
                  <img
                    src={
                      content.backdrop_path
                        ? `${img_500}/${content.backdrop_path}`
                        : unavailableLandscape
                    }
                    alt={content.name || content.title}
                    className="ContentModal__landscape"
                  />
                  <div class="video-container">
                    <iframe
                      // width="450"
                      // height="280"
                      src={`https://www.youtube-nocookie.com/embed/${video}`}
                      frameborder="0"
                      allowFullScreen
                    ></iframe>
                  </div>
                </div>

                <div className="ContentModal__about">
                  <span className="ContentModal__title">
                    {content.name || content.title} (
                    {(
                      content.first_air_date ||
                      content.release_date ||
                      "-----"
                    ).substring(0, 4)}
                    )
                  </span>
                  {content.tagline ?(
                    <i className="tagline">{content.tagline}</i>
                  ):  ''}

                  {content.vote_average && (
                    <i className="tagline">{content.vote_average.toFixed(1)}</i>
                  )}

                  <span className="genres__section">
                    {content.genres.map((item) => (
                        <span>{item.name}</span>
                    ))}
                  </span>

                  <span className="ContentModal__description">
                    {content.overview}
                  </span>
                  <div>
                    <Carousel id={id} media_type={media_type} />
                  </div>
                </div>
              </div>
            </div>
          )}
        </Fade>
      </Modal>
    </>
  );
}

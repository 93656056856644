import React, { useEffect, useRef, useState } from "react";
import logo from "../../data/images/logo.png";
import { Container, Row } from "reactstrap";
import { useNavigate, NavLink } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";

import "./header.css";
import { toggleActions } from "../../toggle/toggle-item/lightToggle";

const nav__links = [
  {
    path: "home",
    display: "Home",
  },
  {
    path: "series",
    display: "Series",
  },
  {
    path: "movies",
    display: "Movies",
  },
  {
    path: "trending",
    display: "Trending",
  },
  {
    path: "search",
    display: "Search",
  },
  // {
  //   path: "pages",
  //   display: "Pages",
  // },

  // {
  //   path: "contact",
  //   display: "Contact",
  // },
];

const Header = () => {
  const navigate = useNavigate();

  const headerRef = useRef(null);
  const menuRef = useRef(null);
  const menuToggle = () => menuRef.current.classList.toggle("active__menu");


  const isLight = useSelector((state) => state.light.isLight);

  const dispatch = useDispatch();

  const lightToggle = () => {
    dispatch(toggleActions.toggle());
  };

  const stickyHeaderFunc = () => {
    window.addEventListener("scroll", () => {
      if (
        document.body.scrollTop > 100 ||
        document.documentElement.scrollTop > 100
      ) {
        headerRef.current.classList.add("sticky__header");
      } else {
        headerRef.current.classList.remove("sticky__header");
      }
    });
  };



  useEffect(() => {
    stickyHeaderFunc();

    return () => window.removeEventListener("scroll", stickyHeaderFunc);
  }, []);

  return (
    <header
      className={`header ${isLight ? "light-toggle" : "dark-toggle"}`}
      ref={headerRef}
    >
      <Container>
        <Row>
          <div className="nav__wrapper">
            <div className="logo ">
              <img src={logo} alt="logo" onClick={() => navigate("/")} />
            </div>
            <div className="navigation" ref={menuRef} onClick={menuToggle}>
              <ul className="menu">
                {nav__links.map((item, index) => (
                  <li className="nav__item" key={index}>
                    <NavLink
                      to={item.path}
                      className={(navClass) =>
                        navClass.isActive ? "nav__active" : ""
                      }
                    >
                      {item.display}
                    </NavLink>
                  </li>
                ))}
              </ul>
            </div>

            {/* <div className="nav__icons">
              <div className="searchBox">
                <input
                  type="text"
                  className="searchInput"
                  name=""
                  placeholder="Search"
                />
                <button className="searchButton" href="#">
                  <i class="ri-search-line"></i>
                </button>
              </div>
            </div> */}
            
            <div
              className={`lightbar ${isLight ? "dark-toggle" : "light-toggle"}`}
              onClick={lightToggle}
            >
              <span>
                <i class="ri-lightbulb-line"></i>
              </span>
            </div>
            <div className="menubar">
              <span onClick={menuToggle}>
                <i class="ri-menu-line"></i>
              </span>
            </div>
          </div>
        </Row>
      </Container>
    </header>
  );
};

export default Header;

import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "./slider.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from "axios";

import SingleContent from "../UI/SingleContent";
import { Col } from "reactstrap";

const AutoPlay = ({ movieData,movieType = ''}) => {

  var settings = {
    dots: true,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 2,
    autoplay: true,
    // speed: 2000,
    autoplaySpeed: 5000,
    cssEase: "linear",
    arrows: true,
    pauseOnHover: true,
    swipeToSlide: true,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          dots: true,
          infinite: true,
          slidesToShow: 3,
          slidesToScroll: 2,
          autoplay: true,
          autoplaySpeed: 5000,
          cssEase: "linear",
          arrows: false,
          pauseOnHover: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          dots: false,
          infinite: true,
          slidesToShow: 2,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 5000,
          cssEase: "linear",
          arrows: false,
          pauseOnHover: true,
        },
      },
      {
        breakpoint: 500,
        settings: {
          dots: false,
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 5500,
          cssEase: "linear",
          arrows: false,
          pauseOnHover: true,
        },
      },
    ],
  };

  return (
    <div className="cart__section-slider">
      <Slider {...settings}>
        {movieData.map((item,index) => (
          <Col
            lg="12"
            className="d-flex align-items-center justify-content-center"
            key={index}
          >
            <SingleContent
              key={item.id}
              id={item.id}
              poster={item.poster_path}
              title={item.title || item.name}
              date={item.first_air_date || item.release_date}
              media_type={item.media_type ? item.media_type : movieType}
              vote_average={item.vote_average}
            />
          </Col>
        ))}
      </Slider>
    </div>
  );
};

export default AutoPlay;

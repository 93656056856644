import { createSlice } from "@reduxjs/toolkit";

const lightToggle = createSlice({
  name: "light",
  initialState: {
    isLight: false,
  },

  reducers: {
    toggle(state) {
      state.isLight = !state.isLight;
    },
  },
});

export const toggleActions = lightToggle.actions;
export default lightToggle;

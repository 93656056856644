import axios from "axios";
import React, { useEffect, useState } from "react";
import AutoPlay from "../components/slider/Slider";
import { Container, Row, Col } from "reactstrap";
import { useSelector } from "react-redux";

import "../styles/home.css";

const Home = () => {
  const [contentTrending, setContentTrending] = useState([]);
  const [contentMovies, setContentMovies] = useState([]);
  const [contentSeries, setContentSeries] = useState([]);

  const fetchTrending = async () => {
    const { data } = await axios.get(`
    https://api.themoviedb.org/3/trending/all/week?api_key=${process.env.REACT_APP_API_KEY}`);

    setContentTrending(data.results);
  };

  const fetchMovies = async () => {
    const { data } = await axios.get(`
    https://api.themoviedb.org/3/discover/movie?api_key=${process.env.REACT_APP_API_KEY}`);
    setContentMovies(data.results);
  };

  const fetchSeries = async () => {
    const { data } = await axios.get(`
    https://api.themoviedb.org/3/discover/tv?api_key=${process.env.REACT_APP_API_KEY}&language=en-US&sort_by=popularity.desc&include_adult=false&include_video=false&page=1&with_genres=`);

    setContentSeries(data.results);
  };

  const isLight = useSelector((state) => state.light.isLight);
  
  const slicedTrends = contentTrending.slice(0, 12);
  const slicedMovies = contentMovies.slice(5, 17);
  const slicedSeries = contentSeries.slice(0, 12);

  useEffect(() => {
    fetchTrending();
    fetchMovies();
    fetchSeries();

    // eslint-disable-next-line
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLight]);

  return (
    <section className={`section ${isLight ? "light-toggle" : "dark-toggle"} `}>
      <Container>
      <div className="movie__section">
        <Row>
          <Col lg="12">
            <h3
              className={`section__name-${
                isLight ? "light" : "dark"
              } text-center mb-5`}
            >
              Trending
            </h3>
          </Col>
          <section
            className={`carousel__section-${isLight ? "light" : "dark"}`}
          >
            <AutoPlay movieData={slicedTrends} />
          </section>
        </Row>

      </div>
        
        <div className="movie__section">
          <Row>
            <Col lg="12">
              <h3
                className={`section__name-${
                  isLight ? "light" : "dark"
                } text-center mb-5`}
              >
                Movies
              </h3>
            </Col>
            <section
              className={`carousel__section-${isLight ? "light" : "dark"}`}
            >
              <AutoPlay movieData={slicedMovies} movieType="movie"/>
              {/* {slicedMovies.map((item) => (
                <SingleContent
                  key={item.id}
                  id={item.id}
                  poster={item.poster_path}
                  title={item.title || item.name}
                  date={item.first_air_date || item.release_date}
                  media_type="movie"
                  vote_average={item.vote_average}
                />
              ))} */}
            </section>
          </Row>
        </div>

        <div className="movie__section">
          <Row>
            <Col lg="12">
              <h3
                className={`section__name-${
                  isLight ? "light" : "dark"
                } text-center mb-5 `}
              >
                TV Series
              </h3>
            </Col>
            <section
              className={`carousel__section-${isLight ? "light" : "dark"}`}
            >
              <AutoPlay movieData={slicedSeries} movieType="tv"/>
            </section>
          </Row>
        </div>
      </Container>
    </section>
  );
};

export default Home;

import React, { useState, useEffect } from "react";
import axios from "axios";

import SingleContent from "../components/UI/SingleContent";
import { Col, Container, Row } from "reactstrap";
import { useSelector } from "react-redux";
import CustomPagination from "../components/pagination/CustomPagination";
import Genres from "../components/Genres";

import useGenre from "../hooks/useGenre";

const Movies = () => {
  const [content, setContent] = useState([]);
  const [page, setPage] = useState(1);
  const [numOfPages, setNumOfPages] = useState();
  const [selectedGenres, setSelectedGenres] = useState([]);
  const [genres, setGenres] = useState([]);
  const genresforURL = useGenre(selectedGenres)


  // &with_genres=${genresforURL}
  const fetchMovies = async () => {
    const { data } = await axios.get(`
    https://api.themoviedb.org/3/discover/movie?api_key=${process.env.REACT_APP_API_KEY}&language=en-US&sort_by=popularity.desc&include_adult=false&include_video=false&page=${page}&with_genres=${genresforURL}`);

    // console.log(data);
    setContent(data.results);
    setNumOfPages(data.total_pages);
  };

  useEffect(() => {
    fetchMovies();
    // eslint-disable-next-line
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page,genresforURL]);

  const isLight = useSelector((state) => state.light.isLight);

  return (
    <section className={`section ${isLight ? "light-toggle" : "dark-toggle"}`}>
      <Container className="container">
        <Row>
          <Col lg="12" md="12">
            <h4 className="container__title">Movies</h4>
          <Genres
            type="movie"
            selectedGenres={selectedGenres}
            setSelectedGenres={setSelectedGenres}
            genres={genres}
            setGenres={setGenres}
            setPage={setPage}
          />
          </Col>
          {content &&
            content.map((item) => (
              <Col
                lg="3"
                md="4"
                sm="6"
                xl="4"
                className="item__position"
                key={item.id}
              >
                <SingleContent
                  key={item.id}
                  id={item.id}
                  poster={item.poster_path}
                  title={item.title || item.name}
                  date={item.first_air_date || item.release_date}
                  media_type="movie"
                  vote_average={item.vote_average}
                />
              </Col>
            ))}
        </Row>

        <Row>
          <CustomPagination setPage={setPage} numOfPages={numOfPages} />
        </Row>
      </Container>
    </section>
  );
};

export default Movies;

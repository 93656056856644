import React from "react";
import { Route, Routes, Navigate, useLocation } from "react-router-dom";

import Home from "../pages/Home";
import Series from "../pages/Series";
import Movies from "../pages/Movies";
import Contact from "../pages/Contact";
import Pages from "../pages/Pages";
import Trending from "../pages/Trending";
import Search from "../pages/Search";

const Routers = () => {
  const location = useLocation();
  return (
    <Routes location={location} key={location.pathname}>
      <Route path="/" element={<Navigate to="home" />} />
      <Route path="home" element={<Home />} />
      <Route path="series" element={<Series />} />
      <Route path="movies" element={<Movies />} />
      <Route path="contact" element={<Contact />} />
      <Route path="pages" element={<Pages />} />
      <Route path="trending" element={<Trending />} />
      <Route path="search" element={<Search />} />
    </Routes>
  );
};

export default Routers;

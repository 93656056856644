import React from "react";
import "./custom-pagination.css";
import { Pagination } from "@mui/material";


import { Col } from "reactstrap";



const CustomPagination = ({ setPage, numOfPages = 10 }) => {
  const handleChange = (page) => {
    setPage(page);
    window.scrollTo({
      top: 100,
      left: 100,
      behavior: "smooth",
    });
  };

  return (
    <Col lg="12">
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          marginTop: "50px",
        }}
      >
          <Pagination
            shape="rounded"
            color="primary"
            count={numOfPages}
            onChange={(e)=>handleChange(e.target.textContent)}
            hideNextButton
            hidePrevButton
          />
      </div>
    </Col>
  );
};

export default CustomPagination;

import axios from "axios";
import React, { useEffect, useState } from "react";

import SingleContent from "../components/UI/SingleContent";
import { Col, Container, Row } from "reactstrap";
import { useSelector } from "react-redux";

import "../styles/trending.css";
import CustomPagination from "../components/pagination/CustomPagination";

const Trending = () => {
  const [content, setContent] = useState([]);
  const [page, setPage] = useState(1);

  const fetchTrending = async () => {
    const { data } = await axios.get(`
    https://api.themoviedb.org/3/trending/all/week?api_key=${process.env.REACT_APP_API_KEY}&page=${page}`);

    setContent(data.results);
  };

  useEffect(() => {
    fetchTrending();
    // eslint-disable-next-line
  }, [page]);

  const isLight = useSelector((state) => state.light.isLight);

  return (
    <section className={`section ${isLight ? "light-toggle" : "dark-toggle"}`}>
      <Container className="container">
        <Row>
          <Col lg="12" md="12">
            <h4 className="container__title">Trendings</h4>
          </Col>
          {content &&
            content.map((item) => (
              <Col lg="3" md="4" sm="6" xl="4" className="item__position">
                <SingleContent
                  key={item.id}
                  id={item.id}
                  poster={item.poster_path}
                  title={item.title || item.name}
                  date={item.first_air_date || item.release_date}
                  media_type={item.media_type}
                  vote_average={item.vote_average}
                />
              </Col>
            ))}
          
        </Row>
        <Row>
          <CustomPagination setPage={setPage}/>
        </Row>
      </Container>
    </section>
  );
};

export default Trending;
